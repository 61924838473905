import React, { useEffect, useMemo, useState } from "react";
import { Col, Row, Space, Spin, Statistic, Tooltip, Typography } from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import CountUp from "react-countup";
import { useQuery } from "react-query";
import { getCashupReport } from "../../../../ApiV2/Helpers/getCashupReport";
import { Formatter, valueType } from "antd/es/statistic/utils";
import { CentredSpinner } from "Components/Misc/Loading/CentredSpinner";
import ShiftReportClassesTenders from "./ShiftReportClassesTenders";
import ShiftReportRevenueMetrics from "./ShiftReportRevenueMetrics";
import ShiftPrompts from "./ShiftPrompts";
import {
    DownloadLoadingContainer,
    KeyNumberContainer,
    LoadingContainer,
    NoShiftDataContainer,
    ShiftMetricsNoDataContainer,
    TableTitle,
} from "../CashupReportStyled";
import { CashupReportType } from "@Constants/enum/Cashup";
import { apiUrlTypes, keyNumbers } from "../../const";
import { checkDateRange, checkZeroValues, downloadPdfReport } from "../../utils";
import { contactSupport } from "utils/utilities";

const { Title } = Typography;

interface ShiftReportProps {
    reportType: string;
    date: { start_date: string; end_date: string };
    venue: string;
    venueName: string;
    calendarStatus: boolean;
}

const CashupShiftReport: React.FC<ShiftReportProps> = ({
    reportType,
    date,
    venue,
    venueName,
    calendarStatus,
}) => {
    const [download, setDownload] = useState(false as boolean);
    const [rangeCheck, setRangeCheck] = useState(false as boolean);

    useEffect(() => {
        if (checkDateRange(date, 7)) {
            setRangeCheck(true);
        } else {
            setRangeCheck(false);
        }
    }, [date]);

    const formatDateString = (dateString: string) => {
        return dayjs(dateString, "DD-MM-YYYY").format("YYYY-MM-DD");
    };

    //get data from report api
    const getReportData = async (url: string) => {
        const reportUrlType = url;
        const formattedStartDate = formatDateString(date.start_date);
        const formattedEndDate = formatDateString(date.end_date);
        //get report data
        if (!calendarStatus && !checkDateRange(date, 7)) {
            const response = await getCashupReport(
                reportUrlType,
                venue,
                formattedStartDate,
                formattedEndDate
            );
            return response.json();
        }
    };

    const useFetchReportData = (reportType: string) => {
        const urlType = apiUrlTypes[reportType];
        return useQuery(
            [reportType, date.start_date, date.end_date],
            () => getReportData(urlType),
            {
                enabled: date.start_date.length > 0 && date.end_date.length > 0,
                refetchOnWindowFocus: false,
            }
        );
    };

    // Shift Metrics API
    const {
        data: shiftReportData = {},
        isFetching: shiftApiStatus,
        refetch: refetchShiftReport,
        error: shiftReportError,
    } = useFetchReportData(CashupReportType.AdminShiftReport);
    // Shift Prompts API
    const {
        data: promptReportData = [],
        isFetching: promptApiStatus,
        refetch: refetchPromptReport,
        error: shiftPromptReportError,
    } = useFetchReportData(CashupReportType.AdminShiftMetrics);
    // POS Classes API
    const {
        data: classesReportData = [],
        isFetching: classesApiStatus,
        refetch: refetchClassesReport,
        error: classesReportError,
    } = useFetchReportData(CashupReportType.PosClasses);
    // POS Tenders API
    const {
        data: tendersReportData = [],
        isFetching: tendersApiStatus,
        refetch: refetchTendersReport,
        error: tendersReportError,
    } = useFetchReportData(CashupReportType.PosTenders);

    useEffect(() => {
        refetchShiftReport();
        refetchPromptReport();
        refetchClassesReport();
        refetchTendersReport();
    }, [
        venue,
        refetchShiftReport,
        refetchPromptReport,
        refetchClassesReport,
        refetchTendersReport,
    ]);

    // Combined status based on all API calls
    const combinedStatus =
        shiftApiStatus || promptApiStatus || classesApiStatus || tendersApiStatus;

    //check 0 values in shift sales revenue data
    const salesNullCheck: boolean = useMemo(() => {
        if (
            shiftReportData &&
            shiftReportData.sales &&
            Array.isArray(shiftReportData.sales.data)
        ) {
            return shiftReportData.sales.data.length > 0
                ? checkZeroValues(shiftReportData.sales.data)
                : false;
        }
        return false;
    }, [shiftReportData]);

    //check 0 values in shift gaming data
    const gamingNullCheck: boolean = useMemo(() => {
        if (
            shiftReportData &&
            shiftReportData.sales &&
            Array.isArray(shiftReportData.gaming_data.data)
        ) {
            return shiftReportData.gaming_data.data.length > 0
                ? checkZeroValues(shiftReportData.gaming_data.data)
                : false;
        }
        return false;
    }, [shiftReportData]);

    const formatter: Formatter = (value: valueType) => {
        if (typeof value === "string") {
            return value;
        }
        return <CountUp end={value} separator="," decimals={2} duration={1} />;
    };

    //download pdf report
    const onClick = async () => {
        setDownload(true);
        await downloadPdfReport("Shift", "shift-report", venueName, date);
        setDownload(false);
    };

    //open quantaco support modal
    const supportClick = () => {
        contactSupport();
    };

    if (date.start_date.length < 1 && date.end_date.length < 1) {
        return (
            <Title level={5}>
                Cashup data is not available for the selected combination of filters.
                Please review and update your selection.
            </Title>
        );
    }

    if (rangeCheck) {
        return (
            <Title level={5}>
                Cashup shift reports are limited to a 7-day date range. Please adjust
                your date range selection.
            </Title>
        );
    }

    return (
        <>
            <div id="shift-report" style={{ backgroundColor: "#f5f5f5" }}>
                <Space
                    size="middle"
                    direction="vertical"
                    style={{
                        width: "100%",
                    }}
                >
                    <Row
                        gutter={[20, 2]}
                        justify="space-between"
                        align="middle"
                        className="hide-for-pdf"
                    >
                        <Col>
                            <Title level={4}>{`${reportType}`}</Title>
                        </Col>
                        <Col>
                            {!combinedStatus ? (
                                <div style={{ display: "flex", gap: "15px" }}>
                                    <Tooltip title="Download PDF">
                                        <FilePdfOutlined
                                            style={{
                                                fontSize: "28px",
                                                color: "#113469",
                                            }}
                                            onClick={onClick}
                                        />
                                    </Tooltip>
                                    {/* Enable in the future
                                <Tooltip title="Download CSV">
                                    <FileTextOutlined
                                        style={{
                                            fontSize: "28px",
                                            color: "#113469",
                                        }}
                                        onClick={onClick}
                                    />
                                </Tooltip>
                                <Tooltip title="Download CSV">
                                    <MailOutlined
                                        style={{
                                            fontSize: "32px",
                                            color: "#113469",
                                        }}
                                        onClick={onClick}
                                    />
                                </Tooltip> */}
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        {keyNumbers &&
                            keyNumbers.map((item: string) => (
                                <Col md={12} lg={6} xs={24} key={item}>
                                    <KeyNumberContainer>
                                        {shiftApiStatus ? (
                                            <CentredSpinner size="large" />
                                        ) : shiftReportError ? (
                                            <>
                                                <TableTitle>
                                                    There was an error when trying to
                                                    retrieve shift report data.
                                                    Please contact{" "}
                                                    <a onClick={supportClick}>
                                                        Quantaco Support
                                                    </a>{" "}
                                                    for assistance.
                                                </TableTitle>
                                            </>
                                        ) : (
                                            <>
                                                {/* {`$${shiftReportData.key_numbers[item]}`} */}
                                                <Statistic
                                                    valueStyle={{
                                                        fontSize: 28,
                                                        fontWeight: "700 !important",
                                                        color: "#113469",
                                                    }}
                                                    value={
                                                        shiftReportData &&
                                                        shiftReportData.key_numbers
                                                            ? shiftReportData
                                                                  .key_numbers[item]
                                                            : 0
                                                    }
                                                    precision={2}
                                                    prefix={"$"}
                                                    formatter={formatter}
                                                />
                                                <div
                                                    style={{
                                                        fontSize: 16,
                                                        fontWeight: "SemiBold",
                                                    }}
                                                >
                                                    {item === "Variance"
                                                        ? "Cash Variance"
                                                        : item}
                                                </div>
                                            </>
                                        )}
                                    </KeyNumberContainer>
                                </Col>
                            ))}
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col xs={24}>
                            {shiftApiStatus ? (
                                <KeyNumberContainer>
                                    <CentredSpinner size="large" />
                                </KeyNumberContainer>
                            ) : shiftReportError ? null : (
                                <>
                                    {!salesNullCheck &&
                                    shiftReportData &&
                                    shiftReportData.sales &&
                                    shiftReportData.sales.data.length > 0 ? (
                                        <ShiftReportRevenueMetrics
                                            revenueData={shiftReportData.sales}
                                            chartLabal={"All Locations"}
                                        />
                                    ) : (
                                        <ShiftMetricsNoDataContainer>
                                            <TableTitle>
                                                Revenue data is not available for the
                                                selected date range.
                                            </TableTitle>
                                        </ShiftMetricsNoDataContainer>
                                    )}
                                </>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col xs={24}>
                            {shiftApiStatus ? (
                                <KeyNumberContainer>
                                    <CentredSpinner size="large" />
                                </KeyNumberContainer>
                            ) : shiftReportError ? null : (
                                <>
                                    {!gamingNullCheck &&
                                    shiftReportData &&
                                    shiftReportData.gaming_data &&
                                    shiftReportData.gaming_data.data.length > 0 ? (
                                        <ShiftReportRevenueMetrics
                                            revenueData={shiftReportData.gaming_data}
                                            chartLabal={"Gaming"}
                                        />
                                    ) : (
                                        <ShiftMetricsNoDataContainer>
                                            <TableTitle>
                                                Gaming data is not available for the
                                                selected date range.
                                            </TableTitle>
                                        </ShiftMetricsNoDataContainer>
                                    )}
                                </>
                            )}
                        </Col>
                    </Row>
                    {classesApiStatus ? (
                        <Row gutter={[20, 2]}>
                            <Col xs={24}>
                                <LoadingContainer>
                                    <CentredSpinner size="large" />
                                </LoadingContainer>
                            </Col>
                        </Row>
                    ) : classesReportError ? (
                        <>
                            <NoShiftDataContainer>
                                <TableTitle>
                                    There was an error when trying to retrieve pos
                                    classes data. Please contact{" "}
                                    <a onClick={supportClick}>Quantaco Support</a>{" "}
                                    for assistance.
                                </TableTitle>
                            </NoShiftDataContainer>
                        </>
                    ) : (
                        <>
                            {classesReportData.length > 0 ? (
                                <ShiftReportClassesTenders
                                    reportData={classesReportData}
                                    type={"Classes"}
                                />
                            ) : (
                                <NoShiftDataContainer>
                                    <TableTitle>
                                        Classes data is not available for the
                                        selected date range.
                                    </TableTitle>
                                </NoShiftDataContainer>
                            )}
                        </>
                    )}
                    {tendersApiStatus ? (
                        <Row gutter={[20, 2]}>
                            <Col xs={24}>
                                <LoadingContainer>
                                    <CentredSpinner size="large" />
                                </LoadingContainer>
                            </Col>
                        </Row>
                    ) : tendersReportError ? (
                        <>
                            <NoShiftDataContainer>
                                <TableTitle>
                                    There was an error when trying to retrieve pos
                                    tenders data. Please contact{" "}
                                    <a onClick={supportClick}>Quantaco Support</a>{" "}
                                    for assistance.
                                </TableTitle>
                            </NoShiftDataContainer>
                        </>
                    ) : (
                        <>
                            {tendersReportData.length > 0 ? (
                                <ShiftReportClassesTenders
                                    reportData={tendersReportData}
                                    type={"Tenders"}
                                />
                            ) : (
                                <NoShiftDataContainer>
                                    <TableTitle>
                                        Tenders data is not available for the
                                        selected date range.
                                    </TableTitle>
                                </NoShiftDataContainer>
                            )}
                        </>
                    )}
                    {promptApiStatus ? (
                        <Row gutter={[20, 2]}>
                            <Col xs={24}>
                                <LoadingContainer>
                                    <CentredSpinner size="large" />
                                </LoadingContainer>
                            </Col>
                        </Row>
                    ) : shiftPromptReportError ? (
                        <>
                            <NoShiftDataContainer>
                                <TableTitle>
                                    There was an error when trying to retrieve shift
                                    prompts data. Please contact{" "}
                                    <a onClick={supportClick}>Quantaco Support</a>{" "}
                                    for assistance.
                                </TableTitle>
                            </NoShiftDataContainer>
                        </>
                    ) : (
                        <>
                            {promptReportData.length > 0 ? (
                                <ShiftPrompts promptsData={promptReportData} />
                            ) : (
                                <NoShiftDataContainer>
                                    <TableTitle>
                                        Prompts data is not available for the
                                        selected date range.
                                    </TableTitle>
                                </NoShiftDataContainer>
                            )}
                        </>
                    )}
                </Space>
            </div>
            {download && (
                <DownloadLoadingContainer>
                    <Spin tip="Generating PDF... Please wait" />
                </DownloadLoadingContainer>
            )}
        </>
    );
};

export default CashupShiftReport;
