import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppendContentTypeHeader } from "./GroupData/UsersAPI";

type VarianceReviewItem = {
    id: string;
    location_type: string;
    location_name: string;
    parent_location_name: string;
    comments: string;
    status: number;
    status_name: string;
    variance_data: {
        [key: string]: number;
    };
};

type VarianceListResponse = {
    variance_list: VarianceReviewItem[];
};

export const varianceReviewDataAPI = createApi({
    reducerPath: "VarianceReviewDataAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_API_URL}`,
        prepareHeaders: async (headers) => {
            const access_token = localStorage.getItem("access_token");
            if (access_token) {
                headers.set("authorization", `Bearer ${access_token}`);
                AppendContentTypeHeader(headers);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getVarianceReviewData: builder.query<
            VarianceListResponse,
            { venue_id: string; shift_date: string }
        >({
            query: (queryParams) => ({
                url: `/api/v1/venue/variance?venue_id=${queryParams.venue_id}&shift_date=${queryParams.shift_date}`,
                method: "GET",
                headers: { "Content-Type": "*/*" },
            }),
            keepUnusedDataFor: 0,
        }),
        patchVarianceReviewData: builder.mutation<
            string,
            { rowItem: VarianceReviewItem }
        >({
            query: (queryParams) => ({
                url: `/api/v1/venue/variance/${queryParams.rowItem.id}`,
                method: "PATCH",
                body: JSON.stringify(queryParams.rowItem),
                headers: { "Content-Type": "application/json" },
            }),
        }),
    }),
});

export const { useGetVarianceReviewDataQuery, usePatchVarianceReviewDataMutation } =
    varianceReviewDataAPI;
