import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PosTotal, SafeData } from "Pages/CashupHome/CashupTabs/POS/@types";
import { VenueItem } from "../../../@generated";
import { AppendContentTypeHeader } from "./UsersAPI";
import { ExtendedClassItem } from "./ClassesAPI";
import { ExtendedAccountItem } from "./AccountsAPI";
import { LocationType } from "Pages/CashupReport/CashupReportModel";

export type ExtendedVenueItem = VenueItem & {
    venue_id: string;
    external_id: string | null;
    active: number;
    name: string;
    group_id: string;
    venueglaccount_set: VenueGlAccount[];
    gaming_machine_counts: number;
    crt_machine_counts?: number;
    use_loose_change?: boolean;
    uses_eftpos_count?: boolean;
    onboarding_date?: string;
    pos_integration_enabled?: boolean;
    pos_enabled_date?: string;
    gaming_enabled_date?: string;
    classes: ExtendedClassItem[];
    accounts: ExtendedAccountItem[];
};

export type VenueGlAccount = {
    gl_account: GlAccount;
};

export type GlAccount = {
    id: number;
    gl_code: string;
    type: string;
};

export type GlAccountByType = {
    type: string;
    glAccounts: GlAccount[];
};

export enum GlAccountTypes {
    EXPENSE = "Expense",
    COSTOFGOODSSOLD = "Cost of Goods Sold",
}

export enum SubmissionStatus {
    UNSUBMITTED = "UNSUBMITTED",
    SUBMITTED = "SUBMITTED",
    APPROVED = "APPROVED",
}

export type timePeriodValues = 1 | 2 | 3; // 1 = AM, 2 = PM , 3 = ALL_DAY

export type SubLocationCashupItem = {
    cashup_id: string;
    status: number;
    last_modified_by: string;
    cashup_date: string;
    location_id: string;
    group_id: string;
    last_modified_date: string; // GMT string
    time_period: timePeriodValues;
    shift_type: number;
};

export type CashupSubLocationItem = {
    location_id: string;
    name: string;
    active: number;
    parent_id: string;
    location_type: LocationType;
    group_id: string;
    external_id: null | string;
    venue_id: string;
    float_value: number;
    cashups: SubLocationCashupItem[];
    time_period: timePeriodValues;
    bank_tech_deposit: boolean;
};

export type VenueCashupsLocationItem = {
    location_id: string;
    name: string;
    active: number;
    netsuite_name: string;

    parent_id: null;
    location_type: LocationType;
    group_id: string;
    external_id: null | string;
    venue_id: string;
    float_value: number;
    sub_locations: CashupSubLocationItem[];
    cashups: [];
    cashup_ids: string[];
};

export interface Cashup {
    cashup_date: string;
    cashup_id: string;
    group_id: string;
    last_modified_by: string;
    last_modified_date: string;
    location_id: string;
    status: number;
    time_period: 1;
}

export interface GamingLocation {
    active: boolean;
    cashup_ids: string[];
    cashups: Cashup[];
    external_id: string;
    float_value: number;
    group_id: string;
    internal: boolean;
    location_id: string;
    location_type: LocationType;
    name: string;
    netsuite_name: string;
    parent_id?: string;
    sub_locations: GamingLocation[];
    venue_id: string;
}

export interface KenoLocation {
    active: boolean;
    cashup_ids: string[];
    cashups: Cashup[];
    external_id: string;
    float_value: number;
    group_id: string;
    internal: boolean;
    location_id: string;
    location_type: LocationType;
    name: string;
    netsuite_name: string;
    parent_id?: string;
    sub_locations: CashupSubLocationItem[];
    venue_id: string;
}

export interface TabLocation {
    active: boolean;
    cashup_ids: string[];
    cashups: Cashup[];
    external_id: string;
    float_value: number;
    group_id: string;
    internal: boolean;
    location_id: string;
    location_type: LocationType;
    name: string;
    netsuite_name: string;
    parent_id?: string;
    sub_locations: CashupSubLocationItem[];
    venue_id: string;
}

export interface WageringLocations {
    active: boolean;
    cashup_ids: string[];
    cashups: Cashup[];
    external_id: string;
    float_value: number;
    group_id: string;
    internal: boolean;
    location_id: string;
    location_type: LocationType;
    name: string;
    netsuite_name: string;
    parent_id?: string;
    sub_locations: CashupSubLocationItem[];
    venue_id: string;
}

export interface SubSafeLocation {
    location_id: string;
    external_id: string;
    active: boolean;
    name: string;
    netsuite_name: string | null;
    group_id: string;
    parent_id: string | null;
    venue_id: string;
    location_type: LocationType;
    float_value: number;
    internal: true;
    mapping_configurator_id: string | null;
    cashups: SubLocationCashupItem[];
    cashup_ids: string[];
}

export interface SafeLocation {
    location_id: string;
    external_id: string;
    active: boolean;
    name: string;
    netsuite_name: string | null;
    group_id: string;
    parent_id: string | null;
    venue_id: string;
    location_type: LocationType;
    float_value: number;
    internal: true;
    mapping_configurator_id: string | null;
    sub_locations: SubSafeLocation[];
    cashups: string[];
    cashup_ids: string[];
}

export interface VenueLocation {
    name: string;
    cashup_ids: string[];
    sub_locations: CashupSubLocationItem[];
}

export interface FloatLocation {
    location_id: string;
    // We subscribe to this for Pusher subscriptions
    external_id: string;
    active: boolean;
    name: string;
    netsuite_name: string | null;
    group_id: string;
    parent_id: string | null;
    venue_id: string;
    location_type: LocationType;
    float_value: number;
    internal: boolean;
    mapping_configurator_id: string | null;
    sub_locations: [];
    cashups: [];
    cashup_ids: [];
    safe_data: SafeData;
}

export type VenuesCashupsResponse = {
    date: string;
    balances: {
        total_sales: number;
        cash_increase: number;
        bankable: number;
    };
    locations: {
        pos_locations: VenueCashupsLocationItem[];
        gaming_locations: GamingLocation[];
        atm_locations: VenueLocation[];
        office_locations: VenueLocation[];
        keno_locations: KenoLocation[];
        tab_locations: TabLocation[];
        crt_locations: VenueLocation[];
        wagering_locations: WageringLocations[];
        float_locations: FloatLocation[];
        safe_locations: SafeLocation[];
        ccu_locations: VenueLocation[];
    };
    channel_id: string;
    pos_totals: PosTotal;
};

export const venuesAPI = createApi({
    reducerPath: "VenuesAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_API_URL}`,
        prepareHeaders: async (headers) => {
            const access_token = localStorage.getItem("access_token");
            // TODO Implement a logging solution to emit no-token events.
            if (access_token) {
                headers.set("authorization", `Bearer ${access_token}`);
                AppendContentTypeHeader(headers);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getVenues: builder.query<ExtendedVenueItem[], null>({
            query: () => ({
                url: "/venues",
                method: "GET",
                headers: { "Content-Type": "*/*" },
            }),
        }),
        getCashupsForVenue: builder.query<
            VenuesCashupsResponse,
            { venue_id: string; cashup_date: string }
        >({
            query: (queryParams) => ({
                url: `/venue/cashups?cashup_date=${queryParams.cashup_date}&venue_id=${queryParams.venue_id}`,
                method: "GET",
                headers: { "Content-Type": "*/*" },
            }),
        }),
    }),
});

export const {
    useGetVenuesQuery,
    useLazyGetCashupsForVenueQuery,
    useGetCashupsForVenueQuery,
} = venuesAPI;
