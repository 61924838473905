import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
    CreateDepositInRequest,
    CreateDepositOutRequest,
    CreateSalesCountRequest,
    CreateTenderCountRequest,
    EftposCountV2Item,
    PaymentV2Item,
    SaleV2Item,
    TransferV2Item,
    UpdateSalesCountRequest,
    UpdateTransferRequest,
} from "../../../@generated";
import { TransactionItem } from "../../../@types";
import { AppendContentTypeHeader } from "./UsersAPI";

// TODO move elsewhere (refactor)
export type CreateSaleTransactionRequest = {
    amount: number;
    memo: string;
    class_split: Array<{ class_id: string; amount: number }>;
    account_split: Array<{
        account_id: string;
        amount: number;
    }>;
    location_id: string;
    // cashup_id: string;
    transaction_date?: string;
    tax_amount?: number;
};
export type CreateTITOTransactionRequest = {
    amount: number;
    description?: string;
    memo: string;
    transaction_date?: string;
    tax_amount?: number;
    location_id: string;
};

export type CreateTransferTransactionRequest = {
    transaction_date?: string;
    amount: number;
    cashup_id: string;
    from_location_id: string;
    memo: string;
    to_location_id: string;
};
export type CreatePaymentTransactionRequest = {
    amount: number;
    // cashup_id: string;
    entity_id?: string;
    location_id: string;
    payment_type_id: number;
    memo: string;
    account_split: Array<{
        account_id: string;
        amount: number;
    }>;
    tax_amount?: number;
    department_id?: string;
};

export type CreateVarianceTransactionRequest = {
    transaction_date: string;
    cashup_id: string;
    variance_account_id: string;
    location_id: string;
    memo: string;
    actual: number;
    expected: number;
    tax_amount?: number;
};

export const transactionsAPI = createApi({
    reducerPath: "transactionsAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_API_URL}`,
        prepareHeaders: async (headers) => {
            const access_token = localStorage.getItem("access_token");
            // TODO Implement a logging solution to emit no-token events.
            if (access_token) {
                headers.set("authorization", `Bearer ${access_token}`);
                AppendContentTypeHeader(headers);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getTransactions: builder.query<TransactionItem[], null>({
            query: () => ({
                url: "/transactions",
                method: "GET",
                headers: { "Content-Type": "*/*" },
            }),
        }),
        createSaleTransaction: builder.mutation<any, Partial<SaleV2Item>>({
            query(body) {
                return {
                    url: "/sale",
                    method: "POST",
                    body,
                };
            },
        }),
        updateSaleTransaction: builder.mutation<
            any,
            Partial<UpdateSalesCountRequest>
        >({
            query(body) {
                return {
                    url: "/sale",
                    method: "PUT",
                    body,
                };
            },
        }),
        createTransferTransaction: builder.mutation<any, Partial<TransferV2Item>>({
            query(body) {
                return { url: "/transfer", method: "POST", body };
            },
        }),
        updateTransferTransaction: builder.mutation<
            any,
            Partial<UpdateTransferRequest>
        >({
            query(body) {
                return { url: "/transfer", method: "PUT", body };
            },
        }),
        createPaymentTransaction: builder.mutation<any, Partial<PaymentV2Item>>({
            query(body) {
                return { url: "/payment", method: "POST", body };
            },
        }),
        updatePaymentTransaction: builder.mutation<
            any,
            Partial<CreatePaymentTransactionRequest & { transaction_id: string }>
        >({
            query(body) {
                return { url: "/payment", method: "PUT", body };
            },
        }),
        createVarianceTransaction: builder.mutation<
            any,
            Partial<CreateVarianceTransactionRequest>
        >({
            query(body) {
                return { url: "/variance", method: "POST", body };
            },
        }),
        updateVarianceTransaction: builder.mutation<
            any,
            Partial<CreateVarianceTransactionRequest & { transaction_id: string }>
        >({
            query(body) {
                return { url: "/variance", method: "PUT", body };
            },
        }),
        createDepositIn: builder.mutation<any, Partial<CreateDepositInRequest>>({
            query(body) {
                return { url: "/deposit/in", method: "POST", body };
            },
        }),
        updateDepositIn: builder.mutation<
            any,
            Partial<CreateDepositInRequest & { transaction_id: string }>
        >({
            query(body) {
                return { url: "/deposit/in", method: "PUT", body };
            },
        }),
        createDepositOut: builder.mutation<any, Partial<CreateDepositOutRequest>>({
            query(body) {
                return { url: "/deposit/out", method: "POST", body };
            },
        }),
        updateDepositOut: builder.mutation<
            any,
            Partial<CreateDepositOutRequest & { transaction_id: string }>
        >({
            query(body) {
                return { url: "/deposit/out", method: "PUT", body };
            },
        }),
        createTenderCount: builder.mutation<any, EftposCountV2Item>({
            query(body) {
                return { url: "/tendercount", method: "POST", body };
            },
        }),
        updateTenderCount: builder.mutation<
            any,
            Partial<CreateTenderCountRequest & { transaction_id: string }>
        >({
            query(body) {
                return { url: "/tendercount", method: "PUT", body };
            },
        }),

        createTITO: builder.mutation<any, Partial<CreateTITOTransactionRequest>>({
            query(body) {
                return { url: "/tito", method: "POST", body };
            },
        }),
        updateTITO: builder.mutation<
            any,
            Partial<CreateTITOTransactionRequest & { transaction_id: string }>
        >({
            query(body) {
                return { url: "/tito", method: "PUT", body };
            },
        }),

        createSalesCount: builder.mutation<any, Partial<CreateSalesCountRequest>>({
            query(body) {
                return { url: "/salescount", method: "POST", body };
            },
        }),
        updateSalesCount: builder.mutation<
            any,
            Partial<CreateSalesCountRequest & { transaction_id: string }>
        >({
            query(body) {
                return { url: "/salescount", method: "PUT", body };
            },
        }),

        deleteTransaction: builder.mutation<
            any,
            Partial<{ transaction_id: string }>
        >({
            query(body) {
                return {
                    url: `/transaction/${body.transaction_id}`,
                    method: "DELETE",
                };
            },
        }),
    }),
});

export const {
    useGetTransactionsQuery,
    useCreateSaleTransactionMutation,
    useCreateTransferTransactionMutation,
    useCreatePaymentTransactionMutation,
    useCreateVarianceTransactionMutation,
    useCreateDepositInMutation,
    useCreateDepositOutMutation,
    useCreateTenderCountMutation,
    useCreateTITOMutation,
    useCreateSalesCountMutation,
    useUpdateDepositInMutation,
    useUpdateDepositOutMutation,
    useUpdatePaymentTransactionMutation,
    useUpdateSaleTransactionMutation,
    useUpdateTransferTransactionMutation,
    useUpdateVarianceTransactionMutation,
    useUpdateTenderCountMutation,
    useUpdateTITOMutation,
    useUpdateSalesCountMutation,
    useDeleteTransactionMutation,
} = transactionsAPI;
