import { Modal } from "antd";
import React, { useCallback, useMemo } from "react";
import { InitDataResponseType } from "../../Pages/CashupHome/CashupTabs/POS/@types";
import { UserItem } from "Redux/StateSlices/GroupData";
import {
    EmptyDiv,
    StyledButton,
    StyledContainer,
    StyledTextArea,
} from "../StyledComponents";

export const ApprovedBySection = ({
    tableData,
    users,
    tableLength,
}: {
    tableData: InitDataResponseType[];
    users: UserItem[];
    tableLength: number;
}) => {
    const fetchUserNameById = useCallback(
        (id: string) => {
            const user = users.find((user) => user.id === id);
            return user?.email ?? "";
        },
        [users]
    );

    const emptyArray = useMemo(() => {
        const tempArray = new Array(tableLength + 1);
        tempArray.fill(0);
        return tempArray;
    }, [tableLength]);

    return (
        <div
            style={{
                width: "100%",
                marginTop: 20,
                marginBottom: 20,
                display: "flex",
                minWidth: "1200px",
            }}
        >
            {emptyArray.map((item, key) => {
                if (key === 0)
                    return (
                        <EmptyDiv numberofitems={emptyArray.length} key={key}>
                            Signed off by
                        </EmptyDiv>
                    );
                const signedOffUserId =
                    tableData[key - 1]?.safe_data?.signed_off_by_user_id;
                const isEmail = (value: string) =>
                    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
                return (
                    <StyledContainer numberofitems={emptyArray.length} key={key}>
                        <StyledTextArea
                            numberofitems={emptyArray.length}
                            key={key}
                            autoSize
                            disabled
                            value={
                                isEmail(signedOffUserId)
                                    ? signedOffUserId
                                    : signedOffUserId
                                    ? fetchUserNameById(signedOffUserId)
                                    : ""
                            }
                        />
                    </StyledContainer>
                );
            })}
        </div>
    );
};
export const ApproveButtonSection = ({
    onApprove,
    tableLength,
    tableData,
    disabled,
}: {
    onApprove: (index: number) => void;
    tableLength: number;
    tableData: InitDataResponseType[];
    disabled: boolean;
}) => {
    const onButtonPress = (key: number) => {
        Modal.confirm({
            title: "Are you sure you want to sign off on this Cashup?",
            content: "This action cannot be undone",
            okText: "Yes",
            cancelText: "No",
            onOk: () => {
                onApprove(key);
            },
            centered: true,
            // CENTER THIS
        });
    };

    const emptyArray = useMemo(() => {
        const tempArray = new Array(tableLength + 1);
        tempArray.fill(0);
        return tempArray;
    }, [tableLength]);

    return (
        <div
            style={{
                width: "100%",
                marginTop: 20,
                marginBottom: 20,
                display: "flex",
                minWidth: "1200px",
            }}
        >
            {emptyArray.map((item, key) => {
                if (key === 0)
                    return <EmptyDiv numberofitems={emptyArray.length} key={key} />;
                return (
                    <StyledContainer numberofitems={emptyArray.length} key={key}>
                        <StyledButton
                            type="primary"
                            key={key}
                            numberofitems={emptyArray.length}
                            onClick={() => onButtonPress(key - 1)}
                            disabled={
                                tableData[key - 1]?.safe_data
                                    ?.signed_off_by_user_id !== undefined || disabled
                            }
                        >
                            {tableData[key - 1]?.safe_data?.signed_off_by_user_id !==
                            undefined
                                ? "Signed off"
                                : "Sign off"}
                        </StyledButton>
                    </StyledContainer>
                );
            })}
        </div>
    );
};
