import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GroupDataState } from "./GroupData";
import { ExtendedVenueItem } from "./GroupData/VenuesAPI";

export const groupdataAPI = createApi({
    reducerPath: "GroupdataAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_API_URL}/group`,
        prepareHeaders: async (headers) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            const access_token = localStorage.getItem("access_token");
            if (access_token) {
                headers.set("authorization", `Bearer ${access_token}`);
                headers.set("Content-Type", "*/*");
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getGroupData: builder.query<GroupDataState, null>({
            query: () => ({
                url: "/data",
                method: "GET",
            }),
        }),
    }),
});

export const { useGetGroupDataQuery } = groupdataAPI;

export const groupdataV2API = createApi({
    reducerPath: "GroupdataV2API",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_API_URL}/api/v2/group/data/`,
        prepareHeaders: async (headers) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            const access_token = localStorage.getItem("access_token");
            if (access_token) {
                headers.set("authorization", `Bearer ${access_token}`);
                headers.set("Content-Type", "*/*");
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getGroupDataV2: builder.query<GroupDataState, { venueId: string }>({
            query: ({ venueId }) => ({
                url: `/` + venueId + "/",
                method: "GET",
            }),
        }),
    }),
});

export const { useGetGroupDataV2Query } = groupdataV2API;
export const venueDataAPI = createApi({
    reducerPath: "venueDataAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_API_URL}/api/v1/venues`,
        prepareHeaders: async (headers) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            const access_token = localStorage.getItem("access_token");
            if (access_token) {
                headers.set("authorization", `Bearer ${access_token}`);
                headers.set("Content-Type", "*/*");
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getVenueData: builder.query<ExtendedVenueItem[], null>({
            query: () => ({
                url: "",
                method: "GET",
            }),
        }),
    }),
});

export const { useGetVenueDataQuery } = venueDataAPI;
