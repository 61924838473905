import { CollapsibleTable } from "../../Pages/CashupHome/CashupTabs/POS/utils/Panel";
import { Space } from "antd";
import React, { useState } from "react";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { CentredSpinner } from "../../Components/Misc/Loading/CentredSpinner";
import { useQuery } from "react-query";
import { getLocationsQuery } from "../../ApiV2/Helpers/getLocationsQuery";
import { OfficePanel } from "./OfficePanel";
import { ExtendedLocationItemWithChildren } from "../../Redux/StateSlices/GroupData/LocationsAPI";
import { getAccountsQuery } from "../../ApiV2/Helpers/getAccountsQuery";
import { getClassesQuery } from "../../ApiV2/Helpers/getClassesQuery";
import dayjs from "dayjs";
import {
    LocationType,
    ShiftType,
    ShiftTypeLabels,
} from "Pages/CashupReport/CashupReportModel";

const OfficeTab = () => {
    const { venueId, formattedDate, shiftStatus } = useVenueSelection();
    const {
        data: officeLocations = [],
        isLoading: officeLocationsIsLoading,
        isFetching,
    } = useQuery<ExtendedLocationItemWithChildren[]>(
        ["collapsableOfficeLocations", venueId, formattedDate],
        () =>
            getLocationsQuery(
                [
                    LocationType.pos,
                    LocationType.gaming,
                    LocationType.keno,
                    LocationType.trade_till,
                    LocationType.bank,
                    LocationType.safe,
                    LocationType.office,
                    LocationType.atm,
                    LocationType.tab,
                    LocationType.crt,
                    LocationType.wagering,
                    LocationType.tab_trade_till,
                    LocationType.keno_trade_till,
                    LocationType.float,
                    LocationType.gaming_trade_till,
                    LocationType.ccu,
                ],
                venueId,
                formattedDate
            )
    );
    const { data: accounts, isLoading: isAccountsLoading } = useQuery(
        ["accounts", venueId],
        () => getAccountsQuery(venueId)
    );
    const { data: classes, isLoading: isClassesLoading } = useQuery(
        ["classes", venueId],
        () => getClassesQuery(venueId)
    );
    const [currentTableInFocus, setCurrenTableInFocus] = useState<string>();

    const getLocations = (subLocations: ExtendedLocationItemWithChildren[]) => {
        const offices = dayjs(formattedDate).isAfter("2024-08-30")
            ? subLocations.some(
                  (a) => a.location_type === LocationType.keno_trade_till
              )
                ? officeLocations.filter(
                      // @ts-ignore
                      (a) =>
                          a.location_type === LocationType.bank ||
                          a.location_type === LocationType.office
                  )
                : officeLocations.filter(
                      (a: ExtendedLocationItemWithChildren) =>
                          a.location_type === LocationType.trade_till ||
                          a.location_type === LocationType.tab_trade_till ||
                          a.location_type === LocationType.keno_trade_till ||
                          a.location_type === LocationType.bank ||
                          a.location_type === LocationType.safe ||
                          a.location_type === LocationType.office ||
                          a.location_type === LocationType.float ||
                          a.location_type === LocationType.gaming_trade_till
                  )
            : officeLocations;

        // Check if a "safe" location exists (location_type === 6)
        const safeLocationIndex = offices.findIndex(
            (location) => location.location_type === LocationType.safe
        );

        if (safeLocationIndex !== -1) {
            // Pop the first sub_location of the safe location
            const safeLocation = offices[safeLocationIndex];
            const poppedSubLocation = safeLocation.sub_locations?.[0];
            const shiftTypeCount = (poppedSubLocation as any)?.cashups?.length;

            if (poppedSubLocation && shiftTypeCount > 0) {
                // Clone the sub_location `shiftTypeCount` times with modified location_id and name
                const newSubLocations = Array.from(
                    { length: shiftTypeCount },
                    (_, index) => {
                        const shiftType = (index + 1) as ShiftType;
                        return {
                            ...poppedSubLocation,
                            location_id: `${
                                poppedSubLocation.location_id.split(" ")[0]
                            } ${shiftType}`,
                            name: ShiftTypeLabels[shiftType],
                        };
                    }
                );

                // Add the new sub_locations to the safe location's sub_locations
                safeLocation.sub_locations = [...newSubLocations];

                // Update the safe location in the offices array
                offices[safeLocationIndex] = safeLocation;
            }
        }

        // Loop through all float locations and update them
        offices.forEach((location, index) => {
            if (location.location_type === LocationType.float) {
                location.location_id = location.location_id.split(" ")[0] + " 1";
                offices[index] = location;
                console.log("Updated floatLocation", location);
            }
        });
        return offices;
    };

    if (
        officeLocationsIsLoading ||
        isAccountsLoading ||
        isClassesLoading ||
        isFetching
    ) {
        return (
            <CentredSpinner
                style={{
                    marginTop: "50px",
                    position: "absolute",
                    textAlign: "center",
                    left: "50%",
                }}
                size={"large"}
            />
        );
    }

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {officeLocations
                .filter((oL) => oL.location_type === LocationType.office)
                .map((currentLocations, index) => {
                    const panelId = `${formattedDate}-${index}`;

                    return (
                        <CollapsibleTable
                            name={currentLocations.name}
                            panelId={panelId}
                            key={panelId}
                        >
                            <OfficePanel
                                key={currentLocations.name}
                                name={currentLocations.name}
                                //@ts-ignore
                                cashupIds={currentLocations.cashup_ids}
                                //@ts-ignore
                                subLocations={currentLocations.sub_locations}
                                hierarchicalLocations={getLocations(
                                    currentLocations.sub_locations
                                )}
                                allLocations={officeLocations}
                                accountsData={accounts}
                                classesData={classes}
                                onClickHandler={() => setCurrenTableInFocus(panelId)}
                                isInFocus={panelId === currentTableInFocus}
                                submitted={shiftStatus !== "UNSUBMITTED"}
                            />
                        </CollapsibleTable>
                    );
                })}
        </Space>
    );
};
export default OfficeTab;
