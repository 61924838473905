import config from "./config.json";

export type StringDictionary = Record<string, string>;

export const ApiCall = async (
    data: string | undefined | FormData,
    userType: string,
    services: string,
    service: string,
    method: string,
    addHeaders: StringDictionary,
    path?: string
): Promise<globalThis.Response> => {
    type userTypeKey = keyof typeof config.api.uri;
    const userKey = userType as userTypeKey;
    const userServices = config.api.uri[userKey];
    type userServicesTypeKey = keyof typeof userServices;

    const servicesKey = services as userServicesTypeKey;

    const userService = userServices[servicesKey];

    type userServiceTypeKey = keyof typeof userService;

    const serviceKey = service as userServiceTypeKey;

    // const url = "https://cvlds.onrender.com/";
    // const url = "http://localhost:8080/";
    const url =
        services === "NOTIFICATIONS"
            ? process.env.REACT_APP_NOTIFICATIONS_API_URL
            : process.env.REACT_APP_BACKEND_API_URL;
    const uri: string = userService[serviceKey];
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    let fullUrl = `${url}${uri}`;

    if (path !== undefined) {
        fullUrl = `${fullUrl}${path}`;
    }
    const ID_TOKEN = localStorage.getItem("access_token");

    addHeaders["Authorization"] = `Bearer ${ID_TOKEN}`;

    // For now, consider the data is stored on a static `users.json` file
    return await fetch(`${fullUrl}`, {
        headers: { ...addHeaders },
        method,
        body: data,
        mode: "cors",
    })
        // the JSON body is taken from the response
        // re-evaluate parsing error body message
        .then(async (res) => {
            if (!res.ok) {
                const contentType = res.headers.get("content-type");
                if (contentType && contentType.includes("application/json")) {
                    const errorResponse = await res.json();
                    //adding status code here for now, can re-evaluate
                    errorResponse["status_code"] = res.status;
                    throw errorResponse;
                } else {
                    throw new Error("Received content is not JSON");
                }
            }
            return res;
        });
};
