import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppendContentTypeHeader } from "./GroupData/UsersAPI";

type BankableItem = {
    bankable: string;
    cash_increase: string;
    date: string;
};

export const bankableDataAPI = createApi({
    reducerPath: "BankableDataAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_API_URL}`,
        prepareHeaders: async (headers) => {
            const access_token = localStorage.getItem("access_token");
            if (access_token) {
                headers.set("authorization", `Bearer ${access_token}`);
                AppendContentTypeHeader(headers);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getBankableData: builder.query<
            Array<BankableItem>,
            { venue_id: string; from_date: string; to_date: string }
        >({
            query: (queryParams) => ({
                url: `/api/v2/analytics/overview?from_date=${queryParams.from_date}&to_date=${queryParams.to_date}&venue_id=${queryParams.venue_id}`,
                method: "GET",
                headers: { "Content-Type": "*/*" },
            }),
            keepUnusedDataFor: 0,
        }),
    }),
});

export const { useGetBankableDataQuery } = bankableDataAPI;
