import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { EntityItem } from "../../../@generated";
import { AppendContentTypeHeader } from "./UsersAPI";

export type ExtendedEntityItem = EntityItem & { entity_id: string };

export const entitiesAPI = createApi({
    reducerPath: "EntitiesAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_API_URL}`,
        prepareHeaders: async (headers) => {
            const access_token = localStorage.getItem("access_token");
            // TODO Implement a logging solution to emit no-token events.
            if (access_token) {
                headers.set("authorization", `Bearer ${access_token}`);
                AppendContentTypeHeader(headers);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getEntities: builder.query<ExtendedEntityItem[], null>({
            query: () => ({
                url: "/entities",
                method: "GET",
                headers: { "Content-Type": "*/*" },
            }),
        }),
    }),
});

export const { useGetEntitiesQuery } = entitiesAPI;
