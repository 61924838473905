import styled from "styled-components";
import { level2Spacing, level3Spacing } from "utils/style-utils";

const StyledFormContainer = styled.div`
    width: 90vw;
    max-height: 50vh;
    overflow-y: auto;
    .ant-collapse-header {
        padding: ${level2Spacing}px ${level3Spacing}px !important;
    }
    .ant-collapse-content-box {
        padding: ${level3Spacing}px !important;
    }
`;

export { StyledFormContainer };
