export interface RemoteAccountSplit {
    amount: number;
    account_id: string;
}

export interface RemoteClassSplit {
    amount: number;
    class_id: string;
}

export interface RemotePosData {
    amount?: number;
    account_split?: RemoteAccountSplit[];
    class_split?: RemoteClassSplit[];
}

export interface RemoteGamingData {
    jackpot?: number;
    total_wins?: number;
    turnover?: number;
}

export interface ReportAtmData {
    refills: number;
    withdrawls: number;
}

export interface RemoteCashCount {
    actual: number;
    expected: number;
    opening_balance: number;
    variance: number;
}

export interface RemoteKenoData {
    cash_pays: number;
    heads_tails: number;
    keno_bet: number;
    magic_millions: number;
    maintainence: number;
    paid_to_keno: number;
    commission_after: number;
    stationary: number;
}

export interface RemoteTabData {
    bet_types: { s_bet: number; trackside_sales: number };
    commission: number;
    costs: number;
    money_in: number;
    money_out: number;
    paid_to_tab: number;
    commission_after: number;
}

export interface RemoteTransferTransaction {
    amount: number;
    from_location_id: string;
    to_location_id: string;
}

export interface RemotePaymentTransaction {
    account_split: RemoteAccountSplit[];
    amount: number;
    memo: string;
    payment_type_id: PaymentType;
}

export interface RemoteSalesCountTransaction {
    turnover: number;
    unclaimed: number;
    wins: number;
}

export interface RemoteEftposCountTransaction {
    actual: number;
    expected: number;
}

export interface RemoteDepositInTransaction {
    amount: number;
    memo: number;
}

export interface RemoteDepositOutTransaction {
    amount: number;
    memo: number;
}

export interface RemoteCashupDetail {
    pos_data: RemotePosData;
    gaming_data: RemoteGamingData;
    cash_balance: number;
    atm_data: ReportAtmData;
    keno_data: RemoteKenoData;
    tab_data: RemoteTabData;
    cash_count: RemoteCashCount;
    transfer_transactions: RemoteTransferTransaction[];
    transfer_transactions_to: RemoteTransferTransaction[];
    payment_transactions: RemotePaymentTransaction[];
    sales_count_transactions: any;
    eftpos_count_transactions: RemoteEftposCountTransaction[];
    deposit_out_transactions: RemoteDepositOutTransaction[];
    deposit_in_transactions: RemoteDepositInTransaction[];
}

export interface RemoteCashup {
    id: string;
    body: RemoteCashupDetail;
    date: string;
    locationType: LocationType;
    locationName: string;
    locationId: string;
}

// ==================== Local Model ===========================

export enum LocationType {
    pos = 1,
    gaming = 2,
    keno = 3,
    trade_till = 4,
    bank = 5,
    safe = 6,
    office = 7,
    atm = 8,
    tab = 9,
    crt = 10,
    wagering = 11,
    tab_trade_till = 12,
    keno_trade_till = 13,
    float = 14,
    gaming_trade_till = 15,
    ccu = 16,
}

export enum ShiftType {
    "START" = 1,
    "CHANGE_OVER" = 2,
    "CLOSE" = 3,
    "POST_CASH_UP" = 4,
    "MID_SHIFT" = 5,
}

export const ShiftTypeLabels: Record<ShiftType, string> = {
    [ShiftType.START]: "Start",
    [ShiftType.CHANGE_OVER]: "Change Over",
    [ShiftType.CLOSE]: "Close",
    [ShiftType.POST_CASH_UP]: "Post Cash Up",
    [ShiftType.MID_SHIFT]: "Mid Shift",
};

export enum PaymentType {
    Expense = 1,
    PettyCash = 2,
    Payout = 3,
}

export interface AccountSplit {
    amount: number;
    accountId: string;
}

export interface ClassSplit {
    amount: number;
    classId: string;
}

export interface PosData {
    amount: number;
    accountSplit?: AccountSplit[];
    classSplit?: ClassSplit[];
}

export interface GamingData {
    jackpot: number;
    totalWins: number;
    turnover: number;
}

export interface AtmData {
    refills: number;
    withdrawls: number;
}

export interface KenoData {
    cashPays: number;
    headsTails: number;
    kenoBet: number;
    magicMillions: number;
    maintainence: number;
    paidToKeno: number;
    stationary: number;
    commissionAfter: number;
}

export interface TabData {
    betTypes: { sBet: number; tracksideSales: number };
    commission: number;
    costs: number;
    moneyIn: number;
    moneyOut: number;
    paidToTab: number;
    commissionAfter: number;
}

export interface CashCount {
    actual: number;
    expected: number;
    openingBalance: number;
    variance: number;
}

export interface TransferTransaction {
    amount: number;
    fromLocationId: string;
    toLocationId: string;
}

export interface PaymentTransaction {
    accountSplit: AccountSplit[];
    amount: number;
    memo: string;
    paymentType: PaymentType;
}

export interface SalesCountTransaction {
    turnover: number;
    unclaimed: number;
    wins: number;
}

export interface EftposCountTransaction {
    actual: number;
    expected: number;
}

export interface DepositInTransaction {
    amount: number;
    memo: number;
}

export interface DepositOutTransaction {
    amount: number;
    memo: number;
}

export interface CashupDetail {
    posData: PosData;
    gamingData: GamingData;
    atmData: AtmData;
    kenoData: KenoData;
    tabData: TabData;
    cashCount: CashCount;
    transferTransactions: TransferTransaction[];
    // receive money
    transferTransactionsTo: TransferTransaction[];
    paymentTransactions: PaymentTransaction[];
    salesCountTransactions: SalesCountTransaction[];
    eftposCountTransactions: EftposCountTransaction[];
    depositInTransactions: DepositInTransaction[];
    depositOutTransactions: DepositOutTransaction[];
}

export interface Cashup {
    id: string;
    body: CashupDetail;
    date: string;
    locationType: LocationType;
    locationName: string;
    locationID?: string;
}

export interface CashupOptionalBody {
    id: string;
    body?: CashupDetail;
    date: string;
    locationType: LocationType;
    locationName: string;
    locationID?: string;
}

export interface CashupReportItem {
    key: string;
    date: string;
    food: number;
    beverage: number;
    total: number;
    other: number;
    accommodation?: number;
    turnover?: number;
    net_profit?: number;
}

export interface ShiftReportPrompt {
    promptText: string;
    promptType: number;
    promptLocationId: string;
    promptId: string;
    venueId: string;
}

export enum PromptCategory {
    Compliance = 1,
    "Maintenance" = 2,
    "Function Events" = 3,
    Entertainment = 4,
    Food = 5,
    Training = 6,
    Bars = 7,
    Gaming = 8,
    "Retail Bottleshop" = 9,
    Accommodation = 10,
}
