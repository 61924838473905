import React, { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { Nav } from "quantaco-menu";
import auth0 from "auth0-js";
import { useMsal } from "@azure/msal-react";
import { config } from "utils/auth0/config";
import "quantaco-menu/style.css";
import { Notification } from "Types/Notification";
import { getNotifications } from "ApiV2/Helpers/getNotifications";
import { postNotification } from "ApiV2/Helpers/postNotification";

const NewNavBar = () => {
    const { instance, accounts } = useMsal();
    const quantacoHomeUrl = process.env.REACT_APP_QUANTACO_PLATFORM_URL;
    const [token] = React.useState<string | null>(
        localStorage.getItem("access_token")
    );

    //get notifications list
    const {
        data: notifications = [],
        isLoading: notificationsLoading,
        error: notificationError,
    } = useQuery(["notificationsData", token], () => getNotifications(), {
        //refetch every 5 mins
        refetchInterval: 300000,
    });

    //update notification
    const handleNotificationClick = useCallback(
        async (notification: Notification) => {
            try {
                await postNotification(notification.notification_id);
            } catch (errorResponse) {
                console.log("Notification update error: ", errorResponse);
            }
        },
        []
    );

    return (
        <Nav
            //@ts-ignore
            notifications={notifications}
            onNotificationClick={handleNotificationClick}
            ms_instance={instance}
            accounts={accounts}
            homeUrl={quantacoHomeUrl ? quantacoHomeUrl : "/"}
        />
    );
};

export default NewNavBar;
