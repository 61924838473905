import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CashupResponse } from "../../../API/CashUpCurrent";
import { ExtendedCashupItemType } from "../GroupData";
import { AppendContentTypeHeader } from "./UsersAPI";

export const cashupsAPI = createApi({
    reducerPath: "CashupsAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_API_URL}`,
        prepareHeaders: async (headers) => {
            const access_token = localStorage.getItem("access_token");
            // TODO Implement a logging solution to emit no-token events.
            if (access_token) {
                headers.set("authorization", `Bearer ${access_token}`);
                AppendContentTypeHeader(headers);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getCashups: builder.query<ExtendedCashupItemType[], null>({
            query: () => ({
                url: "/cashups",
                method: "GET",
            }),
            keepUnusedDataFor: 5,
        }),
        getCashupsInit: builder.query<CashupResponse, string>({
            query: (id) => ({
                url: `/cashup/${id}/init`,
                method: "GET",
                headers: { "Content-Type": "*/*" },
            }),
        }),
    }),
});

export const { useGetCashupsQuery, useGetCashupsInitQuery } = cashupsAPI;
