import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { NotificationDataType } from "@types";
import { AppendContentTypeHeader } from "./GroupData/UsersAPI";

type NotificationListResponse = { notification_list: NotificationDataType[] };
export const notificationDataAPI = createApi({
    reducerPath: "NotificationDataAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_API_URL}`,
        prepareHeaders: async (headers) => {
            const access_token = localStorage.getItem("access_token");
            if (access_token) {
                headers.set("authorization", `Bearer ${access_token}`);
                AppendContentTypeHeader(headers);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getNotificationData: builder.query<
            NotificationListResponse,
            { venue_id: string; shift_date: string }
        >({
            query: (queryParams) => ({
                url: `/api/v1/venue/notifications?venue_id=${queryParams.venue_id}&shift_date=${queryParams.shift_date}`,
                method: "GET",
                headers: { "Content-Type": "*/*" },
            }),
            keepUnusedDataFor: 0,
        }),
        patchNotificationData: builder.mutation<
            string,
            { rowItem: NotificationDataType }
        >({
            query: (queryParams) => ({
                url: `/api/v1/venue/notifications/${queryParams.rowItem.id}`,
                method: "PATCH",
                body: JSON.stringify(queryParams.rowItem),
                headers: { "Content-Type": "application/json" },
            }),
        }),
    }),
});
export const { useGetNotificationDataQuery, usePatchNotificationDataMutation } =
    notificationDataAPI;
